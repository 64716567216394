var en_US = {
  activity: {
    // 彩蛋
    congratulation: 'Congratulation',
    open_hidden_egg: 'You opened Tokenlon’s easter egg!',
    unfortunately: 'Unfortunately',
    you_have_no_prize: "You haven't met the [Egg] yet",
    support_by: 'By $s',
    get_prize_by_tokenlon: 'Complete the transaction through Tokenlon, you will have the chance to get an [Egg]. please try it quickly.',
    exchange_now: 'Enter Tokenlon',
    // 为何中奖
    why_got_prize: 'Why did you win',
    the_transaction: 'The one of your',
    tokenlon_tx: 'Tokenlon transaction',
    blockhash_matched: 'Blockhash hit a special value and will get an Surprise Box Award! ',
    dforce_ad: 'Lendf.Me will be launched soon to faciliate USDx lending and borrowing!',
    dforce_ad2: '',
    more_details: 'More Details',
    exchange: 'Exchange',
    congratulations: 'Congratulations! You won',
    cost: '$s USD',
    pax_notice_1: '1 PAGX = 31.1g Gold',
    pax_notice_2: 'A token backed by physical gold',
    imbtc_notice_1: '1 imBTC = 1 BTC',
    imbtc_notice_2: 'same value with more utility',
    // 活动规则和介绍
    desc_title: 'Tokenlon Surprise Box',
    activity_period: 'Time',
    activity_period_detail: '2019.11.11（UTC+8） - 2019.11.13 12:00 PM（UTC+8）',
    activity_endtime: 'Event Settlement Time',
    activity_endtime_detail: 'Each Friday 12:00 settle',
    activity_rules: 'Rule',
    activity_rules_detail: 'The amount of prize in the surprise box depends on the number of ‘1’s in the blockhash',
    activity_notes: 'Notes',
    activity_notes_detail_1: 'Due to the limited number of surprise boxes, first come first served until all surprise boxes are given out. The event may end in advance;',
    activity_notes_detail_2: 'The prize will automatically be sent to the wallet address which was involved in the transaction on Tokenlon;',
    activity_notes_detail_3: 'The ultimate interpretation of the event belongs to Tokenlon and imToken.',
    desc_title_light: 'The new Tokenlon now live! Win Big Prizes!',
    activity_rules_detail_light: 'During the event, the weekly top 3 traders will receive prizes! Come and win!',
    activity_reward: 'Event Rewards',
    activity_reward_1: 'Top 1: 1000 USDx',
    activity_reward_2: 'Top 2: 500 USDx',
    activity_reward_3: 'Top 3: 300 USDx'
  },
  blockHash: {
    title: '开启彩蛋',
    // 彩蛋
    congratulation: 'Congratulation',
    open_hidden_egg: 'You opened Tokenlon’s easter egg!',
    unfortunately: 'Unfortunately',
    you_have_no_prize: "You haven't met the [Egg] yet",
    support_by: 'By $s',
    get_prize_by_tokenlon: 'Complete the transaction through Tokenlon, you will have the chance to get an [Egg]. please try it quickly.',
    exchange_now: 'Enter Tokenlon',
    // 为何中奖
    why_got_prize: 'Why did you win',
    the_transaction: 'The one of your',
    tokenlon_tx: 'Tokenlon transaction',
    blockhash_matched: 'Blockhash hit a special value and will get an Egg Award! ',
    dforce_ad: 'Lendf.Me will be launched soon to faciliate USDx lending and borrowing!',
    dforce_ad2: '',
    exchange: 'Exchange',
    // 活动规则和介绍
    desc_title: 'Tokenlon Easter Egg',
    activity_period: 'Event Period',
    activity_period_detail: '07/31/2019 (GMT+8) ～ 08/30/2019 12:00 (GMT+8)',
    activity_endtime: 'Event Settlement Time',
    activity_endtime_detail: 'Each Friday 12:00 settle',
    activity_rules: 'Event Rules',
    activity_rules_detail_1: '1. During the event, Tokenlon users whose trade hit the right easter egg block hash win 5 USDx!',
    activity_rules_detail_2: '2. The easter egg will be airdropped to your address you traded with, please check it carefully.',
    desc_title_light: 'The new Tokenlon now live! Win Big Prizes!',
    activity_rules_detail_light: 'During the event, the weekly top 3 traders will receive prizes! Come and win!',
    activity_reward: 'Event Rewards',
    activity_reward_1: 'Top 1: 1000 USDx',
    activity_reward_2: 'Top 2: 500 USDx',
    activity_reward_3: 'Top 3: 300 USDx'
  },
  cancel: 'Cancel',
  confirm: 'Confirm',
  please_open_in_imtoken: 'Please open in imToken',
  update_app_title: 'Update imToken to latest version',
  update_app_desc: "Current imToken doesn't work with this dapp. Please visit https://token.im via system browser and download the latest version",
  no_wallet_title: 'No wallets found',
  no_wallet_desc: 'Please check',
  wallet_address: 'Wallet Address',
  weekly_exchange: '7-day trading',
  weekly_top: "This week's Top 5",
  seven_days: '7d',
  wallet_amount: 'Users',
  date: 'Date',
  sponsor: 'Sponsors',
  leaderboard: 'Tokenlon Trade Data',
  no_result: 'Currently no data available',
  en: 'English',
  slogan_1: 'Aggregate optimal prices,',
  slogan_2: 'deliver reliable experience',
  slogan_desc: 'Tokenlon is a trusted decentralized exchange protocol on blockchain networks.',
  launch_dapp: 'Launch Dapp',
  trade_in_imtoken: 'Trade in imToken',
  feature_title: 'Explore our reliable products',
  feat1_title: 'Instant swap',
  feat1_desc: 'Swap popular tokens at optimal prices aggregated from reliable market makers.',
  feat1_button: 'Swap',
  feat2_title: 'Limit order',
  feat2_desc: 'Cost free, hassle free, fill your limit orders at target rates with ease.',
  feat2_button: 'Try now',
  feat3_title: 'LON token',
  feat3_desc: 'Hold LON tokens to participate in community governance, LON staking, and enjoy lower trading fees.',
  feat3_button: 'Stake',
  feat4_title: 'BTC wrap service',
  feat4_desc: 'An easy way to convert Bitcoin assets into WBTC & imBTC.',
  feat4_button: 'Learn more',
  monthly_transactions: 'Transactions',
  monthly_volume: 'Volume',
  monthly_user: 'Trading addresses',
  total_volume: 'Total volume',
  total_transactions: 'Total trades',
  total_wallets: 'Total wallets',
  total_order_success_rate: 'Order success rate',
  benefits_title: 'Trade on ETH network with confidence',
  benefit1_title: 'Gasless trading',
  benefit1_desc: 'No ETH in your wallet? Don’t worry, you can still swap tokens on Tokenlon with ease.',
  benefit2_title: 'Optimal trade price',
  benefit2_desc: 'Aggregate the best rates from Professional Market Makers (PMM) and Automated Market Makers (AMM).',
  benefit3_title: 'Best in class security',
  benefit3_desc: 'We work with renowned audit firms to constantly ensure the security of our contract.',
  benefit4_title: 'High success rate',
  benefit4_desc: 'Our order success rate is well-maintained at above 99% to give you the best swap experience.',
  benefit5_title: 'Reward for every trade',
  benefit5_desc: 'To compensate for gas cost, you will get LON token as a subsidy for each successful trade.',
  benefit6_title: 'Dedicated customer support',
  benefit6_desc: 'Need help from our professional CS team? Contact us anytime via support@tokenlon.im.',
  services_title: 'More services & benefits',
  service1_title: 'Limit order',
  service1_desc: 'To embrace the blooming Layer 2 ecosystems, in addition to the Etheruem network, Tokenlon also launched Limit Order feature on the Arbitrum network.',
  service1_link: 'Learn more',
  service2_title: 'LON tokenomics ',
  service2_desc: 'LON is a utility token issued by Tokenlon, used to align all parties involved in the ecosystem and incentivize participation and expansion of the ecosystem.',
  service2_link: 'Learn more',
  service2_link_2: 'Read litepaper',
  service3_title: 'Trade from imToken wallet',
  service3_desc: 'Natively integrated in the imToken App - a world’s leading decentralized wallet, you can manage and swap tokens anytime anywhere.',
  service3_link: 'Download imToken',
  how_it_works_title: 'How it works',
  work_title1: 'Off-chain Matching',
  work_desc1: 'Market makers provide best-price quotations at any time',
  work_title2: 'On-chain Settlement',
  work_desc2: 'As soon as the user clicks to trade, the order is signed and sent to the 0x protocol smart contract',
  work_title3: 'Instant Swap',
  work_desc3: 'The user receives the new tokens into their imToken wallet after just one or two Ethereum blocks',
  view_documents: 'View document',
  docs_title: 'Developer resouces',
  doc1_title: 'Tokenlon Smart Contract',
  doc1_desc: 'You can take a closer look at our contract and understand how it works.',
  doc2_title: 'Tokenlon SDK',
  doc2_desc: 'Allow other protocols to integrate Tokenlon’s capability.',
  doc3_title: 'Market Maker Onboarding',
  doc3_desc: 'A technical guide for the professional Market Maker to become a partner in Tokenlon ecosystem.',
  subscribe_newsletter: 'Subscribe to Tokenlon newsletter\nCatch up the latest news and updates',
  enter_email_address: 'Enter your email address',
  footer_support: 'Support',
  media_twitter: 'Twitter',
  media_github: 'GitHub',
  media_medium: 'Medium',
  media_discord: 'Discord',
  media_zendesk: 'Help Center',
  download_imtoken: 'Get imToken now!',
  imtoken_desc: 'Multi-chain asset management, Decentralized exchange & DApp browser',
  developer_docs: 'Tokenlon MMSK',
  imtoken_2: 'imToken 2.0',
  business: 'Business',
  resource: 'Resource',
  contact: 'Contact',
  social: 'Community',
  gov_forum: 'Governance Forum',
  snapshot: 'Snapshot',
  data: 'Data',
  data_subtitle: 'Monthly',
  // metadata deprecated
  title: 'Tokenlon Official Website | Trustless token - to - token exchange, based on the 0x protocol',
  keywords: 'Blockchain, cryptocurrency, digital assets, investment, wallet, Ethereum, Bitcoin, EOS, Cosmos, Staking, voting, crypto wallet, tokens, coins, TRON, USDT, BNB, HT',
  description: 'Trustless token-to-token exchange, based on the 0x protocol. Trade BTC, ETH, EOS, Cosmos ATOMs, DAI stablecoins and more',
  generator: 'Tokenlon protocol provides trustless token-to-token exchange, get the best price quotes and enjoy minimum slippage. 99% of your transactions will go through.',
  // metadata
  meta_common_title: 'Tokenlon Protocol |  Decentralized trading at the best prices and experience',
  meta_common_description: 'Tokenlon protocol provides trustless token-to-token exchange, get the best price quotes and enjoy minimum slippage. 99% of your transactions will go through.',
  meta_common_keywords: 'tokenlon, lon exchange, decentralized token exchange, dapp token exchange',
  meta_exchange_title: 'Tokenlon Swap',
  meta_exchange_description: 'Swap tokens that meet Tokenlon’s liquidity requirements, no deposits required. Enjoy the best price quotes and minimum slippage.',
  meta_exchange_keywords: 'decentralized token exchange, exchange tokens, lon exchange, decentralized exchange tokens',
  meta_limit_order_title: 'Tokenlon Limit Order',
  meta_limit_order_description: 'Place your limit orders on Tokenlon to take advantage of volatile price movements. Make use of different expiration dates available.',
  meta_limit_order_keywords: 'arbitrum dex, arbitrum exchange, arbitrum swap, dex with limit orders',
  meta_lon_about_title: 'LON Token',
  meta_lon_about_description: 'LON is a utility token issued by Tokenlon, used to align all ecosystem participants. Holders of LON enjoy discounts on trade fees and the right to vote on proposals.',
  meta_lon_keywords: 'lon coin, lon network, lon, what is lon, tokenlon coin',
  meta_lon_dashboard_title: 'LON Dashboard',
  meta_lon_dashboard_description: 'Find all LON-related data here, such as Tokenlon 24h trade volume, the number of LON holders, amount of LON outstanding and in circulation.',
  meta_lon_trade_title: 'Tokenlon Trade Mining',
  meta_lon_trade_description: 'Check the total reward pool at the current phase of trade mining. Reward distribution breakdowns are shown transparently.',
  meta_lon_liquidity_title: 'LON Liquidity Mining',
  meta_lon_liquidity_description: 'Provide liquidity for LON token and receive rewards in different phases',
  meta_lon_staking_title: 'LON Staking',
  meta_lon_staking_description: 'Stake your LON and receive more LON with each buyback triggered. 60% of transaction fees are distributed to stakers.',
  meta_lon_buyback_title: 'LON Buyback',
  meta_lon_buyback_description: 'Fees collected by Tokenlon are used to buy back LON on the open market. LON bought back is distributed to the treasury and LON stakers.',
  meta_blog_title: 'Tokenlon Blog',
  meta_blog_description: 'Stay up to date with the latest happenings at Tokenlon! We provide weekly updates on product development and upcoming marketing campaigns.',
  meta_imbtc_title: 'imBTC: Bitcoin Made Accessible',
  meta_imbtc_description: 'imBTC is an ERC-777 token that can be exchanged interchangeably with BTC at a 1:1 ratio. Tokenlon supports imBTC swapping seamlessly.',
  meta_imbtc_keywords: 'imbtc, tokenized bitcoin',
  meta_imbtc_dashboard_title: 'imBTC Dashboard',
  meta_imbtc_dashboard_description: 'Find out the number of outstanding imBTC and imBTC redeemed for BTC at any point in time. Verify each imBTC is backed by one BTC.',
  latest_news: 'Latest news',
  view_all: 'View all',
  verification: 'Verify email',
  verify_failed: 'Verification Failed',
  verify_successful: 'Verification Successful',
  seven_days_roman: '7 Days',
  thirty_days_roman: '30 Days',
  tx_amount: 'Tx count',
  my_exchange_data: 'My Trading',
  network_error: 'Network error, please try again later',
  tokenloN_exchange_data: 'Tokenlon Trading',
  top_traders: ' Top 5',
  none: 'None',
  support: 'Support',
  discover: 'Discover',
  wiki: 'Gitbook',
  allowance_open_pending: 'Pending token allowance confirmation',
  allowance_close_pending: 'Pending token allowance cancellation',
  tokenlon_need_allowance: 'Open token allowance for exchange',
  tokenlon_set_allowance: 'Authorize',
  pls_input_a_valid_amount: 'Please enter a valid amount',
  $symbol_insufficient_balance: 'The balance of {{symbol}} is not enough',
  tokenlon_$token_more_than_sell_maximum_$value: 'The maximum sell amount for {{token}} is {{value}}',
  tokenlon_$token_less_than_sell_minimum_$value: 'The minimum sell amount for {{token}} is {{value}}',
  unvalid_input_amount: 'Please enter a valid number',
  tokenlon_$token_more_than_buy_maximum_$value: 'The maximum buy amount for {{token}} is {{value}}',
  tokenlon_$token_less_than_buy_minimum_$value: 'The minimum buy amount for {{token}} is {{value}}',
  maker_order_not_exist: 'Order verification failed, please try again later (4001)',
  token_not_match: 'Order verification failed, please try again later (4002)',
  INSUFFICIENT_MAKER_BALANCE: 'Order verification failed, please try again later (4004)',
  symbol_insufficient_balance_and_miner_fee: 'ETH balance is insufficient to cover the transfer amount and transaction fee',
  invalid_nonce: 'Network is busy, please try again later',
  tokenlon_found_pending_tx: 'Existing pending transactions found. Choose to accelerate the pending transaction or try again until the transaction completed.',
  accelerate_tx: 'Accelerate',
  internet_disconnect_without_rate_updated: ' The network is unstable, please try again later',
  can_not_solve_current_exchange: 'Quotations unavailable for this quantity, please adjust the quantity and try again',
  tokenlon_$token_more_than_$balance: '$s balance is insufficient',
  SIGNATURE_FAILED: 'Order verification failed, please try again later (4003)',
  SANITY_FAILED: 'Quotation verification failed, please try again later (2003)',
  BALANCE_FAILED: 'No quotation, please try again later (1002)',
  NO_DATA: 'No quotation, please try again later (1001)',
  SLP_FAILED: 'Quotation verification failed, please try again later (2001)',
  PERMIT_CHECK_FAILED: 'The wallet address has abnormal behavior and temporarily cannot trade. If you have any questions, please contact bd@tokenlon.im (5001)',
  ESCAPE_DEALT_ORDER: 'The wallet address is blocked, if you have any questions, please contact support@tokenlon.im (5004)',
  LONG_TIME_HOLD: 'The wallet address is blocked, if you have any questions, please contact support@tokenlon.im (5002)',
  FROM_UPBIT: 'The wallet address is blocked, if you have any questions, please contact support@tokenlon.im (5003)',
  tokenlon_network_request_failed: 'The network request failed. Please try again later.',
  tokenlon_tooltip_network_error: 'Bad network connection',
  tokenlon_tooltip_ok: 'Price in real-time updates',
  tokenlon_tooltip_quote_error: 'No price, please try again later',
  reference_price: 'Ref.',
  no_data: 'No Data',
  tokenlon_exchange_pending: 'Pending',
  tokenlon_exchange_timeout: 'Transaction timeout',
  tokenlon_exchange_broadcast: 'Submitted',
  exchange_success: 'Success',
  exchange_failed: 'Failed',
  help_center: 'Help Center',
  tokenlon_leaderboard: 'Tokenlon Leaderboard',
  tokenlon_tutorial: 'Tokenlon Tutorial',
  agree_terms_notice: 'By proceeding, you agree to the',
  terms: 'Terms and Conditions',
  amount_for_buy: 'Buy Amount',
  amount_for_sell: 'Sell Amount',
  connect: 'Connect Wallet',
  exchange: 'Instant Exchange',
  wallet: 'Wallet',
  tokenlon_records: 'Transaction',
  allowance_manage: 'Token Approvals',
  authorized_tokens: 'Authorized Tokens',
  why_need_set_allowance: 'Why is authorization needed',
  why_need_set_allowance_notice_1: 'Tokenlon is an atomic swap service built with smart contracts on Ethereum. It allows users to trade their tokens directly from their wallets.',
  why_need_set_allowance_notice_2: 'By authorization, Tokenlon’s smart contract can then transfer authorized token after users initiate the trade. You will have to authorize tokens before you trade them.',
  set_allowance_step: 'Authorization Steps:',
  set_allowance_step_notice_1: 'Turn on authorization toggle',
  set_allowance_step_notice_2: 'Send authorization transaction',
  set_allowance_step_notice_3: 'Wait for the transaction to succeed',
  set_allowance_step_notice_4: 'Authorization completed',
  set_allowance_warning: 'Notice',
  set_allowance_warning_1: 'In Tokenlon, token authorization is required except exchanging from ETH to other tokens.',
  set_allowance_warning_2: 'Authorization does not affect the security of your assets',
  metamask_notice_1: 'MetaMask not detected, please install first',
  metamask_notice_2: 'Select your wallet',
  install_metamask: 'Install MetaMask wallet',
  connect_wallet: 'Connect wallet',
  metamask_install_btn: 'Install',
  metamask_connect_btn: 'Connect',
  later_to_op: 'Later',
  expected_deal_time: 'Estimated transaction',
  rate: 'Rate',
  limit_price: 'Limit price',
  rate_unavailable: 'No price info',
  charge: 'Fee',
  placed_time: 'Order time',
  trade_detail: 'Trade details',
  no_search_result: 'No relevant token was found',
  no_search_result_desc: 'The token does not meet the <br/>requirements of the Tokenlon protocol.<br/><1>Learn more</1>.',
  lo_no_search_result_desc_1: 'please try to import token by its address',
  lo_no_search_result_desc_2: 'please make sure the address is correct and the token is deployed on current network',
  import_token: 'Import token',
  search_token: 'Search token or contract address',
  search_token_or_address: 'Search name or import address',
  search_by_token_address: 'Search by token address',
  loading: 'Loading',
  tokenlon_web_title: 'Tokenlon - An easy-to-use cryptocurrency DEX',
  tokenlon_web_description: 'A decentralized exchange powered by 0x protocol. Support Ethereum tokens trading.',
  tokenlon_web_keywords: 'Decentralized exchange, 0x protocol, MetaMask, Ethereum, Cryptocurrency, ETH, trading.',
  tokenlon_balance: 'Balance:',
  best_price: 'Best Price',
  miner_fee: 'Miner fee',
  signing: 'Signing',
  sending: 'Sending',
  $token_empty_balance: '$s balance is 0',
  my_wallet: 'My Wallet',
  wallet_connected: 'Wallet connected',
  wallet_disconnected: 'Wallet disconnected',
  connect_walllet: 'Connect Wallet',
  trade_detail_fee: 'Fees',
  estimated_miner_fee: 'Estimated miner fee:',
  trade_detail_charge: 'Trade fee($s%):',
  received_amount: 'Exchanged Amount',
  fee_introduction: 'Fees',
  connect_success: 'Connected',
  connect_dismiss: 'Connect dismiss',
  links: 'Links',
  current_browser_not_supported_title: 'Use Tokenlon in imToken App',
  current_browser_not_supported_desc: 'Wallet connections are not supported in the current browser, please use Tokenlon to trade on the imToken market channel.',
  list_new_token: 'List a New Token',
  imbtc_slogan: 'transparent and trusted',
  imbtc_slogan_desc: 'At any time, you can find the number of outstanding imBTC, imBTC redeemed for BTC, and other processed transactions by querying the imBTC token address and BTC escrow address to ensure that there is a 1:1 BTC behind imBTC.',
  published: 'Token issued',
  holder: 'Holders',
  deposit: 'Custody',
  yearly_yield: 'APR',
  imbtc_contract: 'imBTC contract',
  imBTC: 'imBTC',
  imbtc_title: 'imBTC - More Accessible Bitcoin',
  imbtc_subtitle: 'imBTC is able to work with smart contracts, seamlessly integrate into decentralized transactions and financial services, while injecting liquidity into the Ethereum ecosystem.',
  get_imbtc: 'Get imBTC',
  learn_more: 'Learn more',
  what_is_imbtc: 'What is imBTC?',
  imbtc_is: 'imBTC is an Ethereum token that represents 1:1 the value of bitcoin. Holders can mint, exchange, redeem, and receive the income on imBTC from the Tokenlon platform.',
  imbtc_feat1_title: 'Deposit and issue',
  imbtc_feat2_title: 'Hold and earn',
  imbtc_feat3_title: 'Currency exchange',
  imbtc_feat1_subtitle: 'You can always buy imBTC with BTC or ERC20 tokens as well as redeem your BTC with imBTC.',
  imbtc_feat2_subtitle: 'Holding imBTC will earn you a part of Tokenlon’s imBTC trading fees which current accounts for an annual yield of $s%.',
  imbtc_feat3_subtitle: 'Choose a decentralized exchange, such as Tokenlon DEX, to trade imBTC.',
  how_get_imbtc: 'How to get imBTC',
  get_imbtc1: 'Use ERC 20 tokens to purchase imBTC on Tokenlon',
  get_imbtc2: 'Use BTC to convert imBTC through the imBTC DApp',
  get_imbtc_link: 'Get imBTC',
  faq: 'FAQ',
  q1: 'What is imBTC?',
  q2: 'How is imBTC issued and redeemed?',
  q3: 'How to get imBTC?',
  q4: 'Where is the BTC stored after the subscription of imBTC? Is it safe?',
  q5: 'How does Tokenlon ensure that imBTC with liquidity?',
  q6: 'What benefits will be obtained by holding imBTC?',
  q7: 'How to view and distribute my interest?',
  q8: 'Is there any benefit in selling and transferring imBTC?',
  a1: "imBTC is an ERC777 token-backed 1:1 with Bitcoin, issued and regulated by Tokenlon. Tokenlon supports imBTC exchange and you get interest by holding imBTC (interest from Tokenlon's imBTC exchange fee sharing).",
  a2_1: 'imBTC is not a freely issued token, but is generated by locking an identical amount in BTC. Completely transparent and 100% verifiable.',
  a2_2: "Transfer real BTC to Tokenlon's multi-signature account to lock BTC and to get the same amount of imBTC.  the locked BTC by sending back the imBTC. This approach ensures an accurate 1:1 relationship between imBTC and BTC, while the transparency of the blockchain lets you track and verify the process.",
  a3_1: 'Participate in the imBTC subscription',
  a3_2: 'You can open the "imBTC" DApp in the market interface to participate imBTC subscription, ',
  a3_3: 'here is the tutorial.',
  a3_4: 'Exchange it in Tokenlon',
  a3_5: 'Tokenlon will open the imBTC trading pair, users can use ETH or USDT to exchange imBTC',
  a4_1: "BTC will be stored in Tokenlon's cold wallet, and the cold wallet will ensure the security of BTC assets.",
  a5_1: 'The liquidity of imBTC is reflected in two points below:',
  a5_2: '1. Users can redeem their BTC anytime through imBTC',
  a5_3: '2. Tokenlon can support imBTC trading pairs always, and users can exchange imBTC for other digital assets at anytime',
  a6_1: 'In order to compensate for the liquidity loss of your BTC after purchasing imBTC, Tokenlon will pay your imBTC interest from the two sources of income:',
  a6_2: '1. Fees paid by Tokenlon users for purchasing imBTC',
  a6_3: '2. Handling fees paid by users for redeemption of BTC',
  a6_4: 'Payment will start at 100% of the platform fee as stated above. Then it will be halved every year, that is:',
  a6_5: '100% platform income will be sent to you for your imBTC holding interest in the first year',
  a6_6: '50% platform income will be sent to you for your imBTC holding interest in the 2nd year',
  a6_7: '25% platform income will be sent to you for your imBTC holding interest in the 3rd year',
  a6_8: '12.5% platform income will be sent to you for your imBTC holding interest in the 4th year',
  a6_9: 'and so on.',
  a6_10: 'User benefits = (your imBTC amount / imBTC total amount) * The total interest from Tokenlon platform, the benefits will be sent to your ETH address through imBTC.',
  a7_1: 'You can find your earnings in the imBTC DApp homepage and receive revenue by manual triggering.',
  a7_2: 'Attention:',
  a7_3: '1. Anyone who has imBTC can trigger the function of distributing imBTC revenue. All income will be allocated to users wallet in the proportion to imBTC.',
  a7_4: '2. r distribution, the miner fee has to be paid by whoever makes a distribution request. That users’  wallet needs to have enough ETH to pay it.',
  a8_1: 'Because income = (the number of imBTC that you hold/ total amount of imBTC) * imBTC exchange fee, send through imBTC, so if your address doesn’t have imBTC and you cannot get the benefits.',
  language: 'Language',
  english: 'English',
  chinese: '中文',
  deposit_address: 'Custodian wallet address',
  amount: 'Amount',
  imbtc_db_page_title: 'imBTC - transparent and trusted',
  imbtc_db_page_desc: 'Inspect imBTC token creation, issuance, transfer process by querying the imBTC token address and BTC escrow address, to ensure that imBTC is backed 1:1 by BTC.',
  scan_with_imtoken: 'Scan with imToken',
  deposit_tip: 'There is always a small difference between the collateralized BTC and outstanding imBTC, because the fee for redeeming BTC stays in the collateral contract while a slightly different BTC fee is paid to the miners.',
  otc_buy: 'Buy',
  otc_tip_1: 'The purchase will be provided through a third-party platform and the fee is included in the amount',
  otc_tip_2: 'Less than 150 does not require ID verification',
  otc_next: 'Next',
  otc_min_buy: '50 USD Minimum Purchase',
  otc_max_buy: 'Daily limit $2000',
  unsupported_region: 'Temporary does not support the current region',
  supported_region: 'Support region',
  submit_request: 'Submit your demand',
  otc_min_buy_rmb: '$s RMB Minimum Purchase',
  otc_max_buy_rmb: 'Daily limit ¥ $s',
  otc_min_buy_asset: '$s $s Minimum Purchase',
  otc_max_buy_asset: 'Daily limit $s $s',
  pls_check_network: 'Temporarily unable to get the exchange rate, please check the network',
  cant_solve_this_trade: 'Can not solve this trade currently',
  otc_exchange: 'Exchange',
  trading_disable: 'Trading suspended, please try again later',
  PRICE_FAILED: 'Quotation verification failed, please try again later (2002)',
  BALANCE_INSUFFICIENT: 'Insufficient Balance',
  USER_BALANCE_INSUFFICIENT: 'Insufficient balance',
  already_connecting: 'The network is connecting, please try again later',
  TIMEOUT: 'Order time out, please try again later',
  tried_too_much_times_to_connect: 'Reconnect failed, please try again later',
  disconnect: 'Disconnect',
  approving_pls_sign: 'Sign to approve authorization',
  unApproving: 'Sign to cancel authorization',
  rejected: 'Authorization failed',
  timeout_pls_relaunch: 'Waited too long',
  approve_success: 'Successfully authorized',
  unApprove_success: 'Authorization successfully cancelled',
  unApprove_rejected: 'Cancelation failed',
  view_more_support_wallet: 'More supported wallets',
  use_imtoken_scan: 'Scan to use imToken',
  back: 'Back',
  close: 'Close',
  approve_tokenlon_sell_$token: 'We need permission to use your  $s ',
  approve_token_to_tokenlon: 'We need permission to use your {{symbol}} ',
  unApprove_tokenlon_sell_$token: 'Cancel the $s token allowance for Tokenlon',
  unApprove_token_to_tokenlon: 'Cancel the {{symbol}} token allowance for Tokenlon',
  wrong_network: 'Wrong Network',
  wrong_network_tips: 'Network error. Please switch over to Ethereum Mainnet',
  wrong_network_dev_tips: 'Network error. Please switch over to Testnet',
  sign_metamask_tips: 'Please sign with Metamask',
  sign_walletconnect_tips: 'Please sign with your connected wallet',
  trade_rejected: 'Trade rejected',
  deposit_wbtc: 'WBTC Gateway Custody',
  deposit_tip_wbtc: 'WBTC Custody = WBTC balance + imBTC balance',
  custody_tip_wbtc: 'Note: Tokenlon has set up an exchange pool to allow users to exchange WBTC, which is funded by BTC custody.',
  connect_imkey_wallet: 'Connect imKey Pro hardware wallet',
  connect_imkey_wallet_usb: 'Connect imKey Pro hardware wallet using USB',
  pls_install_first: 'Please Install',
  install_imkey_pro_on_tokenlon: 'Click on the Connect button in Tokenlon Web',
  connect_imkey_btn: 'Connect',
  connecting_imkey_btn: 'Connecting...',
  widthout_imkey: 'Don’t have a hardware wallet? Order a ',
  pls_store_personal_funds: 'and keep your funds in cold storage',
  problem_with_connection: 'Having trouble connecting?',
  get_help: 'Get Help. ？',
  sign_imkey_connect_tips: 'Please sign with imkey pro',
  tradeData: 'Leaderboard',
  developer_document: 'Developer',
  audit_report: 'Audit Report',
  instant_exchange: 'Exchange',
  // LON Dashboard & Referral
  holders: 'Holders',
  tokenlon_24h_vol: 'Tokenlon 24h Volume (USD)',
  my_data: 'My Data',
  lon_balance: 'LON',
  tokenlon_fee: 'Fee Rate',
  reward_record: 'Rewards',
  share_lon_title: 'Invite a friend to benefit from trade reward in power',
  activate: 'Bind',
  share: 'Share',
  lon_invitation_code: 'LON Referral Code',
  invitation_code: 'Referral Code',
  tft_invitation_code: 'TFT Premium Referral Code',
  my_wallet_address: 'My Wallet Address',
  activate_success: 'Bound successfully',
  activate_success_subtitle: 'Start Trade Mining and earn together with your friend',
  forward_to_exchange: 'Start Trading',
  share_friends: 'Invite minimum of 3 friends to active your referral reward. After they complete a trade each, you start earning rewards.',
  tfter_share_friends: 'Invite at least one friend to active your referral reward. After your friend completes a trade, you start earning rewards.',
  share_and_gain_friends_reward: 'After your friend completes a trade, you will start receiving 0.3 * his trading fee in power rewards, you friend will receive 0.7 * his trading fee in power rewards.',
  share_image: 'Share picture',
  share_link: 'Share link',
  weekly_reward: 'Reward',
  reward_amount: 'Reward',
  reward_quantity: 'Reward Quantity',
  invitation_DISABLED: 'Disabled',
  invitation_ENABLED: 'Enabled',
  invitation_PENDING: 'Pending',
  lon_share_title: 'My Referral Code',
  lon_activate_title: 'Activate Referral Code',
  error_activate: 'Bind error',
  success_activate: 'Bound successfully ',
  liquidity: 'Total LON attributed',
  trades: 'Trades',
  check_after_connect_wallet: 'Connect Your Wallet',
  no_invitation_code_yet: 'No referral code yet',
  status: 'Status',
  friends_count: 'Referrals',
  activated_friends: 'Active Referrals',
  reward_count: 'Rewards',
  activated: 'Activated',
  activating: 'Inactivated',
  waiting_drop: 'Waiting for rewards',
  waiting_calc: 'Waiting for attribution',
  total_supply: 'Max Supply',
  round_update_time: 'Updates each Saturday 00:00 AM (UTC)',
  round2_update_time: 'Updates on 2020.12.20 08:00 AM(UTC+8) ',
  weekly_power: 'My power',
  daily_power: 'My power',
  round_power: "This Phase's Power",
  round_reward_status: 'Power/Status',
  weekly_power_rate: 'Power Share',
  claim_time: 'Attribution time',
  reward_thead_power: 'LON Power/Share',
  url_cpoied: 'Already copied',
  invalid_invitation_code: 'Code invalid',
  invitation_tip: 'After waiting for 3 friends to bind the invitation code and trade, start the invitation reward',
  invitation_tip_tft: 'After waiting for a friend to bind the invitation code and trade, start the invitation reward',
  timeframe_weekly: '7 Days',
  timeframe_monthly: '30 Days',
  my_tokenlon_data: 'My Trading',
  exchange_times: 'Trades',
  ranking: 'Ranking',
  tokenlon_trade: 'Total Tokenlon Trading',
  trade_top10: 'Trade Ranking',
  lon_power_data: 'Power Leaderboard',
  my_lon_data: 'My Power',
  tokenlon_power: 'Total Tokenlon Power',
  trade_power: 'Trading Power',
  invitation_power: 'Referral Power',
  invitation_power_trade: 'Invitation Power',
  power_top10: 'Power Ranking',
  power: 'Power',
  timeframe_all: 'All',
  // Landing page
  lon_landing_page_title: 'LON ',
  lon_lading_page_description: 'Tokenlon is a decentralized exchange and payment settlement protocol',
  connect_user_and_participants: 'aligns users and ecosystem participants',
  goto_trading_mining: 'Start Mining',
  visited_mining_data: 'Dashboard',
  why_choise_lon: 'Why LON?',
  flow_inlet: 'Competitive rates',
  millions_user_support: 'Aggregates quotes from multiple liquidity market to offer the best rate ',
  top3_dex: '$4B+ accumulated volume',
  high_success_rate: 'It just works',
  leading_dex_protocol: 'Trades rarely fails with a 99.8% success rate',
  what_can_lon_do: 'LON token utilities',
  incentive: 'Incentives',
  participants_can_get_lon: 'Aligning all ecosystem participants for future growth',
  trade_discount: 'Fee Discount',
  fee_discount: 'Enjoying fee discount by simply holding LON',
  governance: 'Community Ownership',
  governance_make_tokenlon_better: 'Giving community the freedom to govern its DEX',
  lon_fqa: 'FAQ',
  learn_trading_mining: 'Learn more about LON and trade mining',
  lon_count: 'LON maximum supply',
  incentive_plan: 'Incentive Plan',
  buy_back: 'Buyback',
  buy_back_description: 'Fees charged by the Tokenlon protocol will be used to buyback LON on the open market, and the LON obtained from the buyback will be used for staking rewards and treasury reserves',
  distribute_token: 'LON distribution',
  community_reward: 'Community reward',
  core_team: 'Core team',
  stakeholders: 'Stakeholders',
  development_fund: 'Development fund',
  system_design: 'System Architecture',
  system_design_description: 'Currently, it’s hard to find the best price as liquidity is fragmented over the whole DEX market. Tokenlon solves this by aggregating multiple liquidity sources, including professional market makers off-chain and automatic market makers (such as Uniswap, Curve) on-chain, automatically selecting the best trading venue for the user.',
  economic_mechanism: 'Economic Mechanisms',
  economic_buy_back: 'Buybacks',
  economic_staking: 'Staking',
  economic_mining: 'Mining',
  economic_treasury: 'Treasury',
  economic_governance: 'Governance',
  economic_buy_back_description: 'The net fees collected by Tokenlon are used to buyback LON on the open market, and transferred to the treasury and staking reward pool.',
  economic_staking_description: 'LON holders enjoy fee discounts and governance rights by participating in the staking. In return, the stakers receive LON as staking reward.',
  economic_mining_description: 'As long as the total LON is within the maximum cap, each buyback triggers LON mint, which is used as rewards in the Tokenlon Incentive Plan.',
  economic_treasury_description: 'LON is held in the treasury pool governed by the community, used to develop and promote the development of the Tokenlon ecosystem.',
  economic_governance_description: "Tokenlon's governance is opened in stages with decision-making power gradually handed over to the community. Participate on Snapshot.",
  road_map: 'Roadmap',
  view_system_design_drawing: 'Learn More',
  genesis_mining: 'Genesis mining',
  economic_build_model: 'Tokenomics',
  launch_governance: 'Governance bootstrap',
  public_network: 'Open network',
  cross_chain_channel: 'Cross-chain channel',
  LON_litepaper: 'LON litepaper',
  litepaper: 'Litepaper',
  LON_litepaper_description: 'Check out the Litepaper for more details.',
  participate_in_trade: 'Trade Now',
  // etherscan ad
  etherscan_ad_q1: 'What is Tokenlon?',
  etherscan_ad_q2: 'What is LON?',
  etherscan_ad_q3: 'How to earn LON?',
  etherscan_ad_a1: "Tokenlon is a fast and secure decentralized exchange backed by imToken. More than 110k users have traded $2.6 billion in total since Tokenlon's launch last year.",
  etherscan_ad_a2: 'LON is a utility token issued by Tokenlon. It allows LON holders to enjoy a discount on trading fees and participate in staking and governance in the future. LON token circulation will start at the end of this year.',
  etherscan_ad_a3: 'Make any trade on Tokenlon to earn LON for free. The 2nd phase of LON mining is currently in progress, users can claim and transfer/trade LON at the end of 2020.',
  etherscan_ad_warning: 'There is no any kinds of sale of LON, please be aware of scams',
  etherscan_ad_title: 'Trade on Tokenlon and',
  etherscan_ad_subtitle: 'earn LON',
  join_farm: 'Trade now',
  farm_rules: 'More details',
  etherscan_ad_trade_tip: 'Trade and earn LON',
  etherscan_ad_lon_tip: 'How to get LON for free',
  etherscan_lon_trade: 'Circulation starts by the end of the year',
  // stage1
  trade_mining: 'Trade Mining',
  trade_mining_records: 'Trade Mining Records',
  lp_mining: 'Liquidity Mining',
  lp_continue_mining: 'What is continuous mining?',
  lp_continue_mining_detail: 'Starting from the phase IV, LON liqudity mining will come into the continuous mining mode, which means you will not need to redeem LP tokens in order to join the new round of liquidity mining and you can continue to win liquidity mining rewards in each phase in the future.',
  lp_continue_mode: 'Liquidity mining launches the continuous mining mode',
  lp_continue_more: 'Learn more',
  stake_mining: 'Stake mining',
  lp_reward: 'Reward ($s/week)',
  lp_staked: '$s total staked',
  lon_calc_reward: "This week's total LON reward",
  weekly_calc_power: 'Total Power (this week)',
  reward_waiting_for_claim: 'Pending reward',
  claim: 'Claim',
  claim_reward: 'Claim Rewards',
  balance: 'Balance',
  add_liquidity: 'Add liquidity',
  redeem: 'Redeem',
  redeem_lp: 'Redeem LP',
  redeem_all: 'MAX',
  lp_4_tips: 'Starting from the phase IV of LON liquidity mining, you will not need to redeem LP tokens in order to join the new round of liquidity mining, and you can redeem LP tokens or claim the past reward in the new round page.',
  staked: 'Already staked',
  stake: 'Stake',
  stake_input_label: 'Stake Amounts',
  stake_rule: 'Staking rules',
  lp_rule: 'Liquidity Mining Rules',
  genesis_reward: 'Genesis reward',
  exit: 'Exit',
  exit_confirm: 'Proceed',
  exited: 'Claimed and Redeemed',
  redeem_and_claim: 'Redeem all LP tokens and claim LON',
  redeem_and_claim_long: 'Redeem all LP tokens and claim your LON rewards',
  approve: 'Approve',
  approved: 'Approved',
  staking: 'Staking',
  stake_done: 'Successfully staking',
  not_redeemable: 'Not stake to redeem',
  not_claimable: 'No claimable reward',
  not_stakable: 'No stakeable token',
  apy: 'APY',
  claiming: 'Claiming',
  claimed: 'Successfully claimed',
  redeeming: 'Redeeming',
  redeemed: 'Successfully redeemed',
  exiting: 'Exiting liquidity mining',
  over: 'Over',
  page_not_found: 'Page not found',
  predict_reward: 'Est. rewards',
  claimable_reward: 'Rewards',
  lon_to_be_claimed: 'LON to be claimed',
  interim_subtitle: 'Genesis mining ended. Finalizing numbers.',
  configurable_error: 'Please upgrade iOS system or use Metamask to access',
  no_records: 'No records',
  defi_warning: 'Liquidity mining might carry the risk of impermanent loss. Therefore, please evaluate the risks before participating',
  brand: 'Brand',
  brand_banner_title: 'Tokenlon is a leading DEX powered by 0x protocol',
  download_all: 'Download All',
  tokenlon_asset_subtitle: 'Tokenlon will be used as an exchange and payment infrastructure for applications and create a robust global financial market with its ecosystem partners',
  lon_asset_subtitle: 'LON is a utility token issued by Tokenlon, used to align all parties involved in the ecosystem and  incentivize participation and expansion of the ecosystem.',
  imbtc_asset_subtitle: 'imBTC is able to work with smart contracts, seamlessly integrate into decentralized transactions and financial services, while injecting liquidity into the Ethereum ecosystem.',
  download: 'Download',
  system_is_maintaining: 'The system is under maintenance',
  // stage2
  forward_pool: 'See Active Pools',
  closed_pools: 'Previous liquidity mining',
  closed_banner_title: 'Expired Liquidity Mining Pool',
  closed_banner_subtitle: 'Please claim LON reward and redeem LP tokens',
  circulation: 'Circulation',
  closed_note_period_1: 'Phase ',
  closed_note_period_2: ' of liquidity mining has ended. You can claim your reward and redeem LP tokens. We recommend you stake your LON to enjoy up to 49.1% APY without Impermanent loss.',
  final_lp_note: 'LON liquidity mining has ended. You can claim your reward, redeem LP tokens and withdraw your LON. We recommend you to stake your LON tokens and enjoy up to 49.1% APY without impermanent loss.',
  final_lp_note_detail_1: 'LON liquidity mining has ended. You can claim your reward, redeem LP tokens and withdraw your ',
  final_lp_note_detail_2: ' / ',
  final_lp_note_detail_3: ' via ',
  final_lp_note_detail_4: '. We recommend you stake your LON after withdrawal, to enjoy up to 49.1% APY without impermanent loss.',
  final_step_1: 'Step ',
  final_step_2: '',
  final_lp_btn_1: 'View LON Staking',
  final_lp_btn_2: 'Withdraw',
  final_redeem: 'Withdraw Tokens',
  final_lp_redeem_note: 'Tip: We recommend you stake your LON after withdrawal, to enjoy up to 49.1% APY without Impermanent loss.',
  // v5
  token_approval: 'Token Approval',
  computing_power_reward: 'Trading Power',
  computing_invite_power_reward: 'Invitation Power',
  current_slippage_$value_tips: 'Minimum receive & Slippage tolerance: $s%',
  deal_time: 'Deal Time',
  exchange_amount: 'Exchanged Amount',
  exchange_pending: 'Pending',
  find_detail_info: 'Query Details',
  handlingFeeRate: 'Fee Rate ',
  order_expired: 'Quote expired, please try again',
  help_and_feedback: 'Help',
  imkey_connected: 'imKey Connected',
  net_contribute: 'Net Fee',
  packing_failed: 'Failed',
  pay_address: 'From',
  pay_amount: 'Payment Amount',
  receive_address: 'To',
  // spotswap user settings
  settings: 'Settings',
  set_gas_fee_label: 'Pay gas fee with tokens ',
  set_gas_fee_tips: 'Make trades without ETH by turning it on',
  set_slippage: 'Slippage Tolerance ',
  slippage: 'Slippage ',
  slippage_placeholder: 'Custom({{min}}%-{{max}}%)',
  setting_slippage_toast: 'The slippage is updated in real time',
  slippage_$range_changed_warning_message: 'The slippage range has been changed to: $s%',
  slippage_normal_tips: 'Note: Lower slippage will protect you trade from frontrun',
  slippage_invalid_tips: 'Invalid input, custom slippage from {{min}}% to {{max}}%',
  tokenlon_latest_deal: 'Latest ',
  trade_fee: 'Trade Fee',
  trading_volume: 'Exchanged Amount',
  tx_id: 'TxID',
  waiting_for_confirm: 'Waiting for Confirm',
  wallet_is_connected: 'WalletConnect Connected',
  order_preview: 'Trade Preview',
  tokenlon_fee_modal_notation_of_amm: 'Note: The number of tokens you see is what you will get.',
  charge_detail: 'Fee Details',
  discount: 'Discount',
  copy_success: 'Copy success',
  // stage3
  leaderboard_nav: 'Leaderboard',
  updates: 'Updates',
  community: 'Community',
  bounty: 'Bounty',
  forum: 'Forum',
  vote: 'Vote',
  twitter: 'Twitter',
  discord: 'Discord',
  developer: 'Developer',
  tokenlon_sdk: 'Tokenlon SDK',
  mm_onboarding: 'MM Onboarding',
  trade_mining_desc: 'Completing a trade on Tokenlon, you can receive LON rewards, which are generated from the minting triggered during LON buyback. The reward pool will be announced after the conclusion of each round of trade mining. ',
  trade_mining_report: '{{phase}} Phase Trade Mining Report',
  trade_mining_total_volume: 'Total Volume',
  trade_mining_total_rewards: 'Total Rewards',
  trade_mining_participants: 'Participants',
  calculating: 'Calculating ...',
  more_details: 'More details',
  mining_data: 'Trade Mining Stats',
  current_trade_mining_countdown_title: '{{phase}} phase reward accumulating will be ending at',
  current_trade_mining_countdown_tip: 'The countdown concludes this phase of trade mining, while simultaneously commencing a new phase of trade mining.',
  daily_output: 'Daily Output',
  phase0_mining: 'Genesis Mining Reward',
  to_view_data: 'to Stake/View Staking Rewards',
  my_invitation: 'My Invitations',
  my_invitation_tip_1: 'Tip: ',
  my_invitation_tip_2: 'Refer your friends to trade on Tokenlon using your referral code! For every successful trade, you will be rewarded with "invitation power", giving you more LON rewards. Start inviting now! ',
  go_trade: 'Trade',
  activate_btn: 'Bind',
  invite_btn: 'Invite',
  participant: 'Share of Network Participant',
  liquidity_provider: 'Liquidity Providers',
  trader: 'Traders',
  relayer: 'Relayers',
  recommender: 'Referrers',
  power_in_count: 'Current Total Power',
  current_total_trade: 'Total Trading Amount (Current)',
  current_power: 'Current Trading Power',
  current_power_share: 'Current Share of Power',
  current_power_share_info: 'Your current share of Trading Power = your current Trade Power / Trade Power of the entire network. You can estimate the number of LON to be rewarded with your share. Estimated number of LON reward = your current share * current cumulative number of the LON reward for the period. Note: The power of the network will increase as the number of participants and trading activity increases. Therefore, the calculation of the estimated number is for reference only. The actual LON reward will be subject to the final settlement.',
  current_power_myshare_info: 'Your current share of Invitation Power = your current Invitation Power / Invitation Power of the entire network. You can estimate the number of LON to be rewarded with your share. Estimated number of LON reward = your current share × current cumulative number of the LON reward for the period. Note: The power of the network will increase as the number of participants and trading activity increases. Therefore, the calculation of the estimated number is for reference only. The actual LON reward will be subject to the final settlement.',
  total_power: 'Global Power',
  current_total_power: 'Current Global Power',
  current_trade_power: 'Current Trading Power',
  current_invitation_activated: 'Number of Activations (Current)',
  current_invitation_power: 'Current Invitation Power',
  round_no: 'Period Number',
  distributed_time: 'End Date',
  status_claimed: 'Claimed',
  status_expired: 'Expired',
  expired_explain: 'According to the <1>TIP39</1>, the genesis rewards has been transferred to the treasury.',
  round_no_1: 'Phase ',
  round_no_2: '',
  number_of_reward: 'Number Of Rewards',
  trade_reward: 'Trading Rewards',
  trade_amount: 'Amount of Trading',
  referral_reward: 'Invitation Rewards',
  activated_address: 'Activation Address',
  // footer
  support_token: 'Support Token',
  brand_and_logo: 'Brand',
  market_maker_document: 'Market Maker Onboarding',
  smart_contract: 'Tokenlon Smart Contract',
  sdk: 'Tokenlon SDK',
  mmsk_tool: 'MMSK',
  mmproxy_tool: 'MMProxy',
  open_api: 'Open API',
  // lon sub nav
  sub_nav_overview: 'Overview',
  sub_nav_dashboard: 'Dashboard',
  sub_nav_trade_mining: 'Trade Mining',
  sub_nav_liquity_mining: 'Liquidity Mining(Closed)',
  sub_nav_staking: 'Staking',
  sub_nav_buyback: 'Buyback',
  waiting_for_claiming: 'Waiting for Claim',
  unbound: 'unbound',
  // staking
  current_stake_reward: 'Rewards (incl. staked LON)',
  current_stake_reward_tip: 'The rewards will be distributed periodically. The number includes the LON already staked and will change when the xLON balance changes.',
  current_lon_reward: 'Current Redeemable Amount',
  apy_avg: 'APY',
  apy_avg_tips: 'The all-time APY since LON staking has been launched from 1st April, 2021.',
  punish_tips_1: 'Penalty amount = Penalty amount ratio x number of LONs that can be withdrawn on the day.',
  punish_tips_2: 'The maximum penalty ratio is 5%(including all principal and interest), and it decreases linearly with the cooldown period.',
  force_redeem_notice_title: 'Early Redemption Notification',
  keep_waiting: 'Continue waiting',
  confirm_redeem: 'Confirm Redeem',
  unlock: 'Unlock',
  redeem_lon_notice: 'LON Redemption Notice',
  redeem_lon_notice_tips_1: 'Before confirming to redeem LON staking, it is necessary to confirm that the cooldown period is unlocked. The cooldown period is 7days.',
  redeem_lon_notice_tips_2: 'Redeem after the cooldown period ends, you can directly redeem the accumulated income without paying a penalty.',
  redeem_lon_notice_tips_3: 'Redeem before the cooldown period ends, accumulated income you could redeem needs to deduct the penalty for early redeemption.',
  force_redeem: 'Early Redemption',
  force_redeem_penalty: 'Deducted Penalty',
  redeem_immediately: 'Redeem',
  unlock_countdown_title: 'Cooldown period counting down',
  unlock_countdown_subTitle: 'Time remaining until the end of your cooldown period',
  unlock_countdown_tips1: 'You could choose to continue to wait, redeem after the cooldown period ends, and withdraw the accumulated income directly without deducting the penalty.',
  unlock_countdown_tips2: 'Redeem after the cooldown period ends, and you can directly withdraw the accumulated income without paying a fine.',
  unlock_countdown_tips3: 'Redeem before the end of the cooldown period, the accumulated income you can withdraw shall be deducted from the penalty for early withdrawal of the staking.',
  redeem_penalty_title: 'Penality to pay',
  extract_amount: 'Extract Amount',
  input_xlon_amout_placeholder: 'Please input xLON Amount',
  redeem_lon_deposit: 'Redeem LON Staking',
  estimate_rate: 'Estimate Rate',
  current_rate: 'Ratio (Current)',
  estimate_redeem: 'Expected amount to redeem',
  extractable: 'Available amount to withdraw',
  unextract_tips: 'The remaining amount not withdrawn this time will enter into the next cooldown period.',
  // buyback
  buyback_notification: 'Announcement on LON buyback cycle and minting coefficient adjustment',
  buyback_page_title: 'LON Buyback',
  buyback_page_desc: 'Fees from the Tokenlon protocol will be used to buy back LON tokens from the market.',
  "goto": 'Go to',
  buyback_subtitle_balance: 'Tokenlon Income',
  buyback_tips_history_fee: 'The accumulated fee income collected by the Tokenlon protocol is calculated based on the US dollar value of the tokens at the time of capture.',
  buyback_card_history_fee: 'Accumulated Fee',
  buyback_card_current_balance: 'Fee Balance',
  buyback_tips_current_balance: 'This represents the current amount of fees within the Tokenlon protocol. Since the value of captured tokens can vary due to market volatility, the fee balance will adjust accordingly.',
  buyback_card_treasury: 'Treasury',
  buyback_tips_treasury: 'This reflects the current holdings within <0>the Tokenlon treasury</0>. As the value of tokens in the treasury can be influenced by market volatility, the balance will adapt accordingly.',
  buyback_subtitle_buyback: 'Buyback Details',
  buyback_card_buyback_amount: 'Cumulative Buyback',
  buyback_tips_buyback_amount: 'This represents the total LON tokens repurchased over time. With each completed buyback, this value grows incrementally.',
  buyback_card_scaleindex: 'Mint Factor',
  buyback_tips_scaleindex: 'According to the result of TIP40, the mint of LON is temporarily suspended.',
  buyback_chart_price_title: 'LON Price',
  staking_chart_lon_circulation_title: 'LON Circulation',
  price: 'Price',
  chart_date_unit_week: '1W',
  chart_date_unit_month: '1M',
  chart_date_unit_all: 'ALL',
  buyback_chart_assign_title: 'Buyback',
  buyback_chart_assign_title_tips_1: 'Total buyback quantity: The total number of buyback LON allocated to the treasury and the staking mining reward pool',
  buyback_chart_assign_title_tips_2: '- LON Treasury: When the buyback is successful, 30% of the LON will be allocated to the Treasury',
  buyback_chart_assign_title_tips_3: '- Staking Mining reward Pool: 70% of the LON will be allocated to the LON Staking Mining reward Pool when the buyback is successfully bought back.',
  buyback_chart_assign_title_tips_4: 'Minting quantity: When 1 LON is successfully buyback, 1 LON will be minted.',
  buyback_chart_assign_title_tips_5: 'Note: Some tokens will have different allocation ratios, e.g. imBTC / WETH.',
  buyback_history_list_tip: 'The irregular buyback of LON is in progress and will be announced after each round is completed.',
  buyback_history_list_title: 'Records',
  buyback_card_remain_title: 'Contract Fee Balances',
  buyback_tips_remain_title_1: 'This list records the balance of all fees collected by Tokenlon protocol, including',
  buyback_tips_remain_title_2: ". Depending on each token's value, nature, and governance, the system will set different buyback ratios accordingly (e.g., WETH). When a buyback is initiated, the system will split the token balance into pre-set ratios and allocate the LON bought back to the staking reward pool and treasury. Note: Tokens could be allocated to the treasury in two ways: 1) Through LON buyback and then transfer to the treasury, and 2) Skip LON buyback and transfer to the treasury directly.",
  buyback_card_remain_subtitle: 'Anyone can initiate LON buyback',
  buyback_card_remain_action: 'Records',
  buyback_history_list_title_date: 'Date',
  buyback_history_list_title_buyback_fee: 'Fees for Buyback',
  buyback_history_list_title_buyback_fee_tip: 'The accumulated fees that can be used to repurchase LON during this period',
  buyback_history_list_title_buyback_amount: 'Buyback Amount',
  buyback_history_list_title_mint_amount: 'Mint Amount',
  buyback_history_list_title_status: 'Status',
  buyback_remain_table_col_progress: 'Minimum Buyback Progress',
  buyback_min_buyback: 'Minimum Buyback',
  buyback_remain_table_col_progress_tips: 'The minimum buyback available progress bar shows the minimum number of buyback that needs to be reached. When the bar is full, the buyback criteria are met and the buyback can be initiated. When the bar is not full, the buyback criteria are not met and the buyback cannot be initiated.',
  buyback_remain_table_col_value: 'Quantity/Value',
  buyback_remain_table_col_remain: 'Currently/Total Available',
  buyback_remain_table_col_remain_tip_1: 'Buyback quantity available: the total number of LON that can be bought-back.',
  buyback_remain_table_col_remain_tip_1_1: 'Number of buyback available = token dollar value / current LON price',
  buyback_remain_table_col_remain_tip_2: 'Number of buyback available: the number of buyback that can be initiated each time.',
  buyback_remain_table_col_remain_tip_3: 'To smooth the impact of large buyback on the market, and to avoid the wasted miner fees by small buyback. The contract sets a minimum and maximum number of buyback that can be initiated when the limit interval is met. If the limit is not met, no buyback may be initiated. - The number of buyback available = token limit interval / current LON price',
  buyback_remain_table_col_remain_tip_4: 'Number of buyback available = token dollar value / current LON price',
  buyback_remain_table_col_remain_tip_5: 'Note: When the buyback is initiated too frequently, the system will limit the frequency of buyback initiation, so when the limit is initiated, you need to wait until the limit is lifted before trying to initiate a buyback.',
  buyback_remain_table_op_buyback: 'Buyback',
  buyback_remain_table_op_buyback_tips: 'Ready to initiate buyback at present',
  buyback_remain_table_op_closed: 'Not Opening',
  buyback_remain_table_op_closed_tips: 'The token is not ready to initiate buyback',
  buyback_remain_table_op_wait: 'Accumulating',
  buyback_remain_table_op_wait_tips: 'Current miner fees are in accumulation and the amount is not sufficient to buyback for now',
  buyback_remain_table_op_countdown: 'Countdown',
  buyback_remain_table_op_countdown_tips: 'Time remaining until the next buyback could be initiated',
  buyback_remain_table_end: '- The End -',
  buyback_form_label_set_quantity: 'Set Buyback Quantity',
  buyback_form_title: 'Launch a Buyback',
  buyback_form_subtitle: 'When you launch a buyback, the following number of tokens in the contract will be used to buyback LON from the open market and distribute them to the staking reward pool and treasury. For a small miner fee, anyone can launch a buyback.',
  buyback_form_current_quantity: 'Current Available Quantity',
  buyback_form_est_quantity: 'Estimate Quantity',
  buyback_form_submit: 'Confirm',
  // Invitations
  invitation_record: 'Invitations',
  total_invited_friends: 'Cumulative Invitation of Friends',
  total_activated_friends: 'Cumulative activation of friends',
  bond_status: 'Binding Status',
  trade_and_referral_power: 'Activation and Trading Power Rewards (Current)',
  bond_time: 'Binding Time',
  load_more: 'Load More',
  waiting_for_count: 'Waiting for settlement',
  // staking
  staking_title: 'LON Staking',
  staking_subtitle: 'Participating in LON staking allows you to receive LON rewards. xLON serves as the staking certificate.',
  staking_buy_lon: 'Buy LON',
  staking_market: 'LON/xLON Market',
  staking_total_lon_staked: 'Staked LON Amount',
  staking_total_xlon_amount: 'xLON Amount',
  // staking_total_xlon_amount_info: '目前已经在市场上流通的xLON总额。xLON是与LON类似的Token，当您将LON质押到Tokenlon时，您可以获得以xLON计价的质押奖励。每个xLON的价值始终高于一个LON的价值。',
  staking_scale_tips: 'The xLON to LON exchange rate will increase with the continuous distribution of staking rewards.',
  staking_assets_xlon_title: 'xLON Balance',
  // staking_assets_xlon_title_tips: '目前您账户中持有的xLON数量。其中包含您质押后获得的xLON质押奖励，以及质押的LON本金（以xLON计价）。',
  staking_assets_lon_title: 'LON Balance',
  staking_assets_all: 'Total Personal Assets',
  staking_lon_stake: 'LON Staking',
  staking_lon_stake_tips_1: 'Anyone can join LON staking and then receive xLON. xLON can be circulated freely and the value of xLON is anchored with LON. xLON:LON is always greater than or equal to 1 and the ratio changes in real time.',
  staking_lon_stake_tips_2: 'After LON staking, you can redeem LON at any time and receive the proceeds. The redeemption requires payment of miner fees and a 7-day cooling-down period. For early redeemption, the maximum penalty is 5% of xLON (principal and interest).',
  staking_lon_stake_tips_3: 'Note: The ratio decreases linearly with the unlocking period. The penalty will remain in the staking reward pool and be shared by other stakers. For specific details, please refer to the ',
  staking_lon_stake_faq: 'LON staking FAQ',
  staking_input_label_stake: 'Please input stake amount',
  staking_input_placeholder_stake: 'please input LON amount',
  staking_amount_all: 'All',
  staking_currency: 'Ratio (Current)',
  staking_gain: 'Expected amount to get',
  staking_confirm: 'Confirm Staking',
  staking_unstake_tips: 'You could directly withdraw all xLON without penalty after cooldown period ends.',
  transaction_wait_tips: 'Approximately need',
  transaction_view: 'View on Etherscan',
  detail: 'Detail',
  staking_records_title: 'Staking/Redemption Records',
  staking_records_title_tips: 'The rate of xLON : LON changes in real time. Please help refer to below table for more details.',
  staking_records_col_time: 'Staking/Redeem Time',
  staking_records_col_unlocktime: 'Unlock Time',
  staking_records_col_type: 'Event Type',
  staking_records_col_amount: 'Amount',
  staking_records_col_receive: 'Harvest Amount',
  buy: 'Buy',
  sell: 'Sell',
  trigger_cooldown_period: 'Unlock Cooldown',
  withdraw_amount_error_tips: 'Withdraw amount is bigger than available amount',
  status_buyback_ing: 'Buyback in Progress',
  status_buyback_success: 'Buyback Success',
  status_buyback_error: 'Buyback Failure',
  status_stake_ing: 'Staking',
  status_stake_success: 'Staked Successfully',
  status_stake_error: 'Staking Failed',
  status_stake_exiting: 'Exit Staking in Progress',
  status_stake_exit_success: 'Exit Staking Successfully',
  status_stake_exit_error: 'Exit Staking Failed',
  status_unlock_ing: 'Unlocking',
  status_unlock_success: 'Unlock Success',
  status_unlock_error: 'Unlock Failed',
  fold: 'Fold',
  unfold: 'Unfold',
  comming_soon: 'Coming soon',
  lon_holders_enjoy_discount_tips: 'Both LON and xLON holders could enjoy fee discount.',
  trade_success: 'Success',
  check_on_chain_status: 'Check OnChain Status',
  claim_success: 'Claim Successful',
  claim_failed: 'Claim Failed',
  rules_label: "I have read, understood and agreed to Tokenlon's ",
  user_rules: 'Terms of Service and Privacy Policy',
  price_lookup: 'Finding best price...',
  allowance_pending: 'Waiting for Authorization',
  need_allowance: 'Give authorization to swap ',
  select_agreement: 'Accept Terms of Service and Privacy Policy',
  min_received: 'Receive (no slippage)',
  min_received_amm: 'Min. received',
  min_received_tip_default: 'The price is provided by Tokenlon professional market maker, thus this trading has no slippage.',
  get_best_price: 'Tokenlon has found the best price!',
  lookup_failed: 'Failed to get exchange rate',
  select_token: 'Select a token',
  in_calc: 'In Settlement',
  settled_not_issued: 'Waiting for Issue',
  to_claim: 'Rewards',
  all: 'All',
  recommend: 'Recommend',
  common_base: 'Common Base',
  token_select_warning: 'Anyone can create token on chain, please be aware of scams, and do you own research before trading.',
  view_on_Etherscan: 'View on Etherscan',
  view_on_Arbiscan: 'View on Arbiscan',
  i_understand: 'I understand',
  redeem_lon_modal_tips1: 'Before claiming your LON rewards,',
  redeem_lon_modal_tips2: 'please confirm that you have participated in the Tokenlon community governance vote.',
  geo_paragraphs_1: "In order to match the regulatory policy of each user's country of origin, LON DApp has ceased to provide services to users in China starting from 31 October 2021 SGT.",
  geo_paragraphs_2: 'For users in China who have used this service and have not yet redeemed their assets, please rest assured that the security of your assets is not affected.',
  geo_paragraphs_3: 'If you encounter any problems, you can contact us via support@tokenlon.im.',
  geo_link: 'https://tokenlon.gitbook.io/docs/v/docs.en/blog/announcement#related-tutorials',
  geo_btn: 'View detailed tutorial',
  lp_tips: 'Changes to LON Liquidity Mining',
  wallet_connect_failed: 'Failed to connect to wallet',
  imkey_in_maintained: 'We are currently upgrading imKey, please connect wallet via imToken or other options.',
  unsupported_chain_id_error: 'This network is not supported, please switch to {{network}} network and reconnect',
  limit_order: 'Limit Order',
  order_sell: 'Sell',
  order_buy: 'Buy',
  order_receive: 'Receive',
  expired_time: 'Expires in',
  cancelled_time: 'Calculation date',
  expired_time_note: 'Order will be cancelled without fee when expired. ',
  expired_time_setting: 'Set expiration time',
  minutes: 'mins',
  hour: 'hr',
  hours: 'hrs',
  day: 'day',
  days: 'days',
  weeks: 'wks',
  months: 'mths',
  authorize_order: 'Authorize order',
  sign_order_pending: 'Your signature is required to authorize the limit order ...',
  sign_order_success: 'Order submitted. You can check order status in "Open orders". ',
  sign_order_failure: 'Signature rejected. Please try again.',
  cancel_order_pending: 'Cancel order with your signature ...',
  cancel_order_success: 'Order cancelled',
  cancel_order_failure: "Order can't be cancelled. Please check if the order has already been processed. ",
  open_orders: 'Open orders',
  order_history: 'Order history',
  preview_order: 'Review order',
  order_processed: 'Processed',
  order_insufficient_balance: "This order wasn't processed due to insufficient balance.",
  order_insufficient_allowance: 'Insufficient allowance. Please cancel the order and place a new one',
  order_in_deprecated_contract: 'Your order is expired due to system upgrade, please place order again.',
  no_orders: 'No Orders',
  search_token_prompt: "Didn't find the token you were looking for? Use search function to discover more!",
  refresh_market_price: 'Show current market price',
  cancel_order: 'You can cancel order for free.',
  select_wallet: 'Select Wallet',
  select_network: 'Select Network',
  limit_order_status_pending: 'Processing',
  limit_order_status_partialfilled: 'Partially filled',
  limit_order_status_fulfilled: 'Fulfilled',
  limit_order_status_cancelled: 'Cancelled',
  limit_order_status_expired: 'Expired',
  bridges_menu: 'Bridges',
  unmatched_network_message: 'Current service not support {{chain}}，please click Switch to {{expectChain}} network',
  unmatched_network_instant_message: 'Please switch to {{expectChain}} network to swap tokens',
  unmatched_network_limit_order_message: 'Please switch to {{expectChain}} network to use limit orders',
  unmatched_network_swap_message: 'Please switch to the right network to use Tokenlon’s services',
  "switch": 'Switch',
  switch_network: 'Switch Network',
  switch_network_failed: 'Switch Network Failed',
  updated_at: 'Updated at',
  provider_error_code_UNKNOWN_ERROR: "Sorry, there's an issue on our end. Please try again later.",
  provider_error_code_NOT_IMPLEMENTED: "Sorry, there's an issue on our end. Please try again later.",
  provider_error_code_UNSUPPORTED_OPERATION: "Sorry, there's an issue on our end. Please try again later.",
  provider_error_code_NETWORK_ERROR: 'An network problem has occurred. Please try again later',
  provider_error_code_SERVER_ERROR: "Sorry, there's an issue on our end. Please try again later.",
  provider_error_code_TIMEOUT: 'Your request processing is timeout. Please try again later.',
  provider_error_code_BUFFER_OVERRUN: "Sorry, there's an issue on our end. Please try again later.",
  provider_error_code_NUMERIC_FAULT: "Sorry, there's an issue on our end. Please try again later.",
  provider_error_code_MISSING_NEW: "Sorry, there's an issue on our end. Please try again later.",
  provider_error_code_INVALID_ARGUMENT: "Sorry, there's an issue on our end. Please try again later.",
  provider_error_code_MISSING_ARGUMENT: "Sorry, there's an issue on our end. Please try again later.",
  provider_error_code_UNEXPECTED_ARGUMENT: "Sorry, there's an issue on our end. Please try again later.",
  provider_error_code_CALL_EXCEPTION: 'Your transaction was unsuccessful. Please try again later.',
  provider_error_code_INSUFFICIENT_FUNDS: 'Your account balance is insufficient to cover gas fee. Please top up or wait till the gas price is lower.',
  provider_error_code_NONCE_EXPIRED: 'Your transaction was unsuccessful. Please try again later.',
  provider_error_code_REPLACEMENT_UNDERPRICED: 'Your transaction was unsuccessful. Please try again later.',
  provider_error_code_UNPREDICTABLE_GAS_LIMIT: 'Your transaction was unsuccessful. Please try again later.',
  provider_error_code_TRANSACTION_REPLACED: 'Your transaction was unsuccessful. Please try again later.',
  activity_limitorder_title: 'How to deposit tokens',
  activity_limitorder_desc: 'Deposit tokens to <1>Arbitrum</1> to place limit orders. We recommend depositing ETH first. Find the <3>full tutorial here</3>.',
  activity_weth_title: 'Exchange ETH to WETH',
  activity_weth_desc: 'Limit order is only available for ERC-20 tokens, if you want to place orders with ETH, please swap ETH to WETH. (NOTE: Please keep some ETH to pay gas fees). <1>Exchange now</1>.',
  activity_exchange_title: 'Limit order now on Ethererum network',
  activity_exchange_desc: 'You can place a limit order on both Ethereum and Arbitrum network with 0 cost.',
  spread_up: '{{value}}% higher than the market',
  spread_down: '{{value}}% lower than the market',
  "import": 'Import',
  risk_reminder: 'Risk reminder',
  token_select_blocked: 'Token not supported',
  unknown_source: 'Unknown source',
  sign_limit_order_failed_1001: 'The balance of {{inputTokenSymbol}} is not enough',
  sign_limit_order_failed_1002: 'Invalid expiration time',
  sign_limit_order_failed_1003: 'Please authorize the token before trading',
  sign_limit_order_failed_1004: 'Incorrect signature',
  sign_limit_order_failed_1005: 'Get balance failed',
  sign_limit_order_failed_1006: 'Token not supported',
  sign_limit_order_failed_1007: 'The wallet address has abnormal behavior and temporarily cannot trade. If you have any questions, please contact bd@tokenlon.im (1007)',
  sign_limit_order_failed_1008: 'The balance of {{inputTokenSymbol}} is occupied by active orders, please cancel the order and try again.',
  sign_limit_order_failed_1009: 'request body parsing failed, request invalid',
  sign_limit_order_failed_1010: 'Missing OrderHashes request parameters',
  sign_limit_order_failed_1011: 'Missing CancelSig request parameters',
  sign_limit_order_failed_1012: 'No cancellable orders',
  sign_limit_order_failed_1013: "Cannot cancel someone else's order",
  sign_limit_order_failed_1014: "The order doesn't exist",
  sign_limit_order_failed_1015: "Order can't be canceled. The order is already being processed on the chain and will be completed soon (usually within 30 min).",
  sign_limit_order_failed_1016: "Order can't be cancelled. Please check if the order has already been processed.",
  network_label_spot: 'Swap & Limit',
  network_label_limit: 'Limit',
  spread_warning_check: 'I confirm to sell {{symbol}} {{spread}} below market price.',
  trade: 'Trade',
  products: 'Products',
  preview_warning: 'The higher the order amount, the easier to fulfill the order when the limit price is reached',
  preview_warning_title: 'Note',
  preview_warning_content: 'If the order is not executed when hit the limit price, there could be a few reasons for this',
  preview_warning_option_1: 'The taker might make a loss by filling your order: Takers must consider the order size, gas fees, and profit before filling your order. Increasing the trade amount increases the chance of your order being filled.',
  preview_warning_option_2: 'Lack of liquidity: The token you want to trade does not have enough liquidity to fill your order.',
  preview_warning_option_3: 'Lack of funds: Before the limit order is executed, you transferred tokens away from your wallet. Resulting in a lack of funds to execute your order.',
  got_it: 'Got it',
  lo_search_feedback_eth: 'ETH is not supported at the moment, please wrap your ETH to WETH and then place your order.',
  instant_swap: 'Instant Swap',
  gto_dapp: 'BTC Wrap Service',
  ad_banner_label: 'Campaign',
  the_merge_announcement: 'Tokenlon swap service pauses for ETH merge',
  exchange_tab_instant: 'Swap',
  exchange_tab_limit: 'Limit',
  market_rate: 'Market:',
  free_of_charge: 'Free of charge ($0)',
  adjust_the_selling_price: 'Adjust limit price',
  review_order_anyway: 'Review order anyway',
  lo_low_price_sell_warning_content: 'Attentions! You are going to sell {{tokenSymbol}} with the price {{percent}}% lower than the market and it will result in loss of tokens. We recommend you to set a proper limit price.',
  lo_low_price_buy_warning_content: 'Attentions! You are going to buy {{tokenSymbol}} with the price {{percent}}% higher than the market and it will result in loss of tokens. We recommend you to set a proper limit price.',
  tool: 'Toolbox',
  wrap_eth: 'Wrap ETH',
  wrap_eth_desc: 'Wrap or unwrap ETH',
  unwrap_eth: 'Unwrap ETH',
  review: 'Review',
  weth_statement: 'Learn more about <1>WETH</1>',
  review_wrap: 'Review wrap',
  gas_fee: 'Gas Fee',
  trading_fee: 'Trading Fee',
  network: 'Network',
  confirming: 'Confirming',
  confirming_notice: 'Please not quit the page until the transaction is done',
  done: 'Done',
  network_changed: 'Current network has changed, please try it again',
  gas_reserve_warning: 'Every interaction on Ethereum network requires ETH to pay the miner fee, please keep some ETH in your wallet.',
  wrap_failed_info: 'Some reasons might result in failure <1> <2>The gas fee fluctuates greatly and it needs more to complete the transaction</2> <2>Fail to call the WETH contract</2> <2>For specific reasons, please check the transaction hash, please try it again</2> </1>',
  try_again: 'Try again',
  "default": 'Default',
  // tradeFee intro modal
  trade_fee_intro_title: 'Tokenlon Fees',
  ok: 'OK',
  current_trade_fee: 'Current trade fee',
  discounted_trade_fee: 'Discounted',
  stablecoins_trade_fee_tooltip: 'The standard fee between stablecoins is 0.04%',
  standard: 'Standard',
  lon_holding_balance: 'LON balance',
  lon_holding_balance_tip: 'Including the amount of staked LON',
  last_30d_volume: '30-day volume',
  in_30d_volume: '30d volume',
  trade_fee_intro_note: 'Note: The fee is dynamically adjusted based on your last 30 days accumulated volume or LON balance, the fee between stablecoins is 0.04%. Learn more about <1>Tokenlon fees</1>',
  trading_rewards: 'Trading rewards',
  check_lon_rewards: 'Check LON rewards',
  instant_order_pending_tips: 'Pending, Please be aware of changes in wallet balance',
  // lon reward modal
  lon_reward_title: 'Get LON for every trade',
  lon_reward_pool: 'Reward Pool (Current)',
  countdown: 'Countdown',
  lon_reward_note: 'You can check and claim your LON rewards after the countdown is over. Learn more about <1>LON rewards</1>.',
  check_reward: 'Check reward',
  info_trade_fee: 'Trade fee',
  info_trade_fee_intro: 'Tokenlon fees',
  // pay with tokens intro modal
  payWithTokensIntroModal: {
    title: 'Introducing “Pay gas fee with tokens”',
    desc: 'This feature is disabled by default, but you can enable it anytime to make a trade on Tokenlon even you don’t have enough ETH to pay gas fee.',
    subtitle_1: 'Key Features:',
    subtitle_2: 'Note:',
    feature_1: 'When enabled: Except for selling ETH, all trade gas fee is paid with received tokens and the minimum trade amount  is applied.',
    feature_2: 'When disabled: Gas fee is paid with users’ ETH, the minimum trade amount doesn’t apply.',
    feature_3: 'Tip: This feature does not cover the Token Approval cost, hence a small amount of ETH is still required in your wallet for token approval before trading.',
    note: 'Gas fee can surge to an  amount above the value of received tokens. Be aware before trading.'
  },
  // mini set pay mode modal
  miniSetPayModeModal: {
    title: 'Insufficient ETH to pay gas fee',
    desc: 'Sorry, the balance of ETH in your wallet is not enough to cover the gas fee, you can choose:',
    option_1: 'Transfer some ETH in your wallet',
    option_2: 'Turn the button below on to allow make trades without ETH, <1>learn more</1>',
    label: 'Pay gas fee with tokens'
  },
  gas_fee_too_high_warning: 'Please exercise caution as the current gas fee is high, exceeding 50% of the transaction amount.',
  setting_send_order_by_self_toast: 'Pay gas fee with non-ETH tokens disabled.',
  setting_send_order_by_relayer_toast: 'Pay gas fee with non-ETH tokens enabled.',
  recent_trades: 'Recent trades',
  token_value: 'Token value',
  swap_chart_placeholder: 'There is no data for this time interval or the price resource is not found',
  free: 'Free',
  limitOrderTradeFeeIntroModal: {
    title: '0 fee on Tokenlon limit order',
    desc: 'Tokenlon limit order will not charge you any fee, including the gas fee for create, fulfill or cancel the order and the trade fee.'
  },
  lo_wrap_eth_tip: 'Insufficient balance of WETH, please wrap ETH to WETH to continue.',
  lo_small_order_warning_content: 'The sell amount is too small to cover the network fee and the order is unlikely to be executed. To avoid the situation, we suggest you adjust the amount to <1>${{USDValue}}</1> or more.',
  lo_order_pending_tip: 'The order is still pending due to the gas fee fluctuations OR the market price has not hit the limit price. Please wait patiently.',
  note: 'Note',
  adjust_amount: 'Adjust amount',
  continue_to_trade: 'Continue to trade',
  token_allowance_not_enough: 'Insufficient authorization for {{token}}, need to re-authorize.',
  token_allowance_not_enough_and_need_cancel: 'Insufficient authorization for {{token}}. Please cancel the authorization first and then authorize it again.',
  spotswap_eth_on_max_tooltip: '{{balance}} ETH\nThe amount is calculated by substracting the cost of gas fee',
  copy_wc_uri: 'Copy to clipboard',
  copy_wc_uri_success: 'Copied to clipboard!',
  est_fast_sell_price: 'Est. fast sell price',
  est_fast_sell_price_tip: 'Based on the network condition, the closer the limit price is to the estimated fast sell price, the higher the likelihood of the order being executed within a short period of time.',
  est_execute_price: 'Est. execute price',
  est_execute_price_tip: 'Based on the network condition, the market price needs to hit {{executionRate}} to execute the order.',
  in_progress: 'In Progress',
  summary: 'Summary',
  total: 'Total',
  liquidity_pools: 'Liquidity Pools',
  others: 'Others',
  accumulating: 'Accumulating',
  swap_protection_title: 'Swap Protection',
  swap_protection_desc: 'Every trading on Tokenlon will be protected from sandwich attack when slippage happens to ensure the interest of users.',
  est_gas_fee: 'Est. gas fee',
  back_to_swap: 'Back to swap',
  spotswap_successful_modal_desc: 'Every trading on Tokenlon will be protected',
  review_btn: 'Review',
  header_notification: 'Tokenlon 6.0 now supports large order smart routing, aggregating more liquidity sources for better price quotes!',
  header_notification_btn: 'Click here to switch >',
  // 风险地址提示
  TRACKER_LIST: 'The wallet address is blocked, if you have any questions, please contact support@tokenlon.im(5006)',
  MAL_REQ_ORDER: 'The wallet address is blocked, if you have any questions, please contact support@tokenlon.im(5007)',
  BLACK_LIST: 'The wallet address is blocked, if you have any questions, please contact support@tokenlon.im(5008)',
  USDT_BLACKLIST: 'The wallet address is blocked, if you have any questions, please contact support@tokenlon.im',
  FROM_BLOCKSEC: 'The wallet address is blocked, if you have any questions, please contact support@tokenlon.im',
  FROM_OFAC: 'The wallet address is blocked, if you have any questions, please contact support@tokenlon.im(5009)',
  USDC_BLACKLIST: 'The wallet address is blocked, if you have any questions, please contact support@tokenlon.im',
  blocksec: 'The wallet address is blocked, if you have any questions, please contact support@tokenlon.im(5010)',
  tokenlon_is_black_listed: 'The wallet address is blocked, if you have any questions, please contact support@tokenlon.im(5010)'
};
export default en_US;