import React from "react";
var __jsx = React.createElement;
import { useTranslation } from 'react-i18next';
import { StyledBar, StyledTitleWrapper, StyledIcon, StyledCloseIcon, StyledLink } from "./styled";
import IconExclamationMark from "../../assets/images/exclamation-mark-blue.svg";
import IconClose from "../../assets/images/modal-close.svg";

var NotificationBar = function NotificationBar(_ref) {
  var text = _ref.text,
      linkText = _ref.linkText,
      _ref$showIcon = _ref.showIcon,
      showIcon = _ref$showIcon === void 0 ? true : _ref$showIcon,
      _ref$showLink = _ref.showLink,
      showLink = _ref$showLink === void 0 ? true : _ref$showLink,
      handleClose = _ref.handleClose,
      handleLinkClick = _ref.handleLinkClick,
      style = _ref.style;

  var _useTranslation = useTranslation(),
      t = _useTranslation.t;

  return __jsx(StyledBar, {
    style: style
  }, __jsx(StyledTitleWrapper, null, showIcon && __jsx(StyledIcon, {
    src: IconExclamationMark
  }), __jsx("div", null, __jsx("span", null, text), "\xA0", showLink && __jsx(StyledLink, {
    onClick: handleLinkClick
  }, linkText !== null && linkText !== void 0 ? linkText : "".concat(t('learn_more'), " >")))), __jsx(StyledCloseIcon, {
    src: IconClose,
    onClick: handleClose
  }));
};

export default NotificationBar;